import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login.jsx";
import Register from "./components/Register.jsx";
import Lay from "./components/Layout.jsx";
import EditLay from "./components/EditorLayout.jsx";
import Community from "./components/Community.jsx";
import Contact from "./components/Contact.jsx";
import About from "./components/About.jsx";
import Home from "./components/Home.jsx";
import CommunityLayout from "./components/CommunityLayout.jsx";
import CommunityTable from "./components/CommunityTable.jsx";

function App() {
	return (
		<div className="app">
			<Router>
				<Routes>
					<Route path="/" element={<Lay />}>
						<Route index element={<Home />} />

						<Route path="community" element={<CommunityLayout />}>
							<Route index element={<Community />} />
							<Route
								path=":tableRef"
								element={<CommunityTable />}
							/>
						</Route>
						<Route path="/about" element={<About />} />
						<Route path="/contact" element={<Contact />} />

						{/* <Route
							path="dashboard"
							element={
								<ProtectedRoute user={user}>
									<Dashboard user={user} />
								</ProtectedRoute>
							}
						/> */}
						{/* <Route path="*" element={<Error />} /> */}
					</Route>
					<Route path="/login" element={<Login />} />
					<Route path="/register" element={<Register />} />
					<Route path="/edit" element={<EditLay />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
