import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import "../styling/Layout.css";
import { MainTable } from "./MainTable";

const Home = () => {
	const [context, setContext] = useOutletContext();

	return <MainTable tableUid={context} />;
};
export default Home;
