import React, { useRef, useState } from "react";
import bullet from "../assets/bullet.png";
import landing from "../assets/landing.png";
import line from "../assets/line.png";
import size from "../assets/size.png";
import start from "../assets/start.png";
import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	CheckCircleFilled,
	CloseCircleOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import Highlighter from "react-highlight-words";
function columns(
	filter,
	setFilter,
	dataSource,
	openNotification,
	handleVote,
	activeUser,
	searchInput,
	searchText,
	setSearchText,
	searchedColumn,
	setSearchedColumn
	// getColumnSearchProps
) {
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setFilter({ ...filter, searching: true });
		setSearchedColumn(dataIndex);
	};
	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
		setFilter({ ...filter, searching: false });
	};
	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div
				style={{
					padding: 8,
					// background: "#55575a",
					backgroundColor: "#25252c",
					borderRadius: 5,
				}}
				onKeyDown={(e) => e.stopPropagation()}
			>
				<Input
					ref={searchInput}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() =>
						handleSearch(selectedKeys, confirm, dataIndex)
					}
					style={{
						background: "#181a1e",
						marginBottom: 1,
						display: "block",
						color: "white",
						placeholder: "white",
					}}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() =>
							handleSearch(selectedKeys, confirm, dataIndex)
						}
						icon={<SearchOutlined />}
						size="small"
						style={{
							width: 90,
						}}
					>
						Search
					</Button>
					<Button
						onClick={() =>
							clearFilters && handleReset(clearFilters)
						}
						size="small"
						style={{
							width: 90,
						}}
					>
						Reset
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined
				style={{
					color: filtered ? "#b4d3b2" : "#55575a",
					fontSize: 20,
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value)
				: false,
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		filteredValue:
			filter.searching && searchText ? [searchText.toLowerCase()] : [],

		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{
						backgroundColor: "#ffc069",
						padding: 0,
					}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});
	const cols = [
		{
			dataIndex: "score",
			key: "score",
			width: 67,
			sorter: { compare: (a, b) => a.upvote.length - b.upvote.length },
			sortDirections: ["descend"],
			sortIcon: (sorted) => {
				sorted ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				);
			},
			title: () => {
				return (
					<div style={{ marginTop: "30px" }}>
						<span>
							Score{" "}
							<Button
								icon={<CheckCircleFilled />}
								style={{
									backgroundColor: "#811b09",
									color: filter.sorted
										? "#b4d3b2"
										: "#55575a",
									border: "none",
									marginLeft: "5px",
									marginTop: "0px",
									marginBottom: "0px",
								}}
								onClick={(e) =>
									setFilter({
										...filter,
										sorted: !filter.sorted,
									})
								}
							/>
						</span>
					</div>
				);
			},
			render: (_, { key }) => {
				if (typeof dataSource.data[key - 1].score === "undefined") {
					dataSource.data[key - 1].score = 0;
				}

				if (typeof dataSource.data[key - 1].downvote === "undefined") {
					dataSource.data[key - 1].downvote = [];
				}
				if (typeof dataSource.data[key - 1].upvote === "undefined") {
					dataSource.data[key - 1].upvote = [];
				}
				let row = dataSource.data[key - 1];
				return (
					<div className="score-container">
						<ArrowDownOutlined
							className={
								activeUser &&
								row.downvote.includes(activeUser.uid)
									? "score-arrow active"
									: "score-arrow"
							}
							onClick={(e) => {
								if (activeUser) {
									e.stopPropagation();
									handleVote(activeUser.uid, key - 1, false);
								} else {
									e.stopPropagation();
									openNotification("top", "vote");
								}
							}}
						/>
						<p>{row.upvote.length - row.downvote.length}</p>
						<ArrowUpOutlined
							className={
								activeUser &&
								row.upvote.includes(activeUser.uid)
									? "score-arrow active"
									: "score-arrow"
							}
							onClick={(e) => {
								if (activeUser) {
									e.stopPropagation();
									handleVote(activeUser.uid, key - 1, true);
								} else {
									e.stopPropagation();
									openNotification("top", "vote");
								}
							}}
						/>
					</div>
				);
			},
			// filteredValue: filter.sent ? [true] : [],
		},
		{
			title: "Rank",
			dataIndex: "key",
			key: "key",
			width: 67,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 100,
		},
		{
			title: () => (
				<div style={{ marginTop: "30px" }}>
					<span>
						Sent{" "}
						<Button
							icon={<CheckCircleFilled />}
							style={{
								backgroundColor: "#811b09",
								color: filter.sent ? "#b4d3b2" : "#55575a",
								border: "none",
								marginTop: "0px",
								marginBottom: "0px",
							}}
							onClick={(e) =>
								setFilter({
									...filter,
									sent: !filter.sent,
								})
							}
						/>
					</span>
				</div>
			),
			dataIndex: "sent",
			key: "sent",
			width: 63,
			inputType: "boolean",
			// fixed: "left",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
			filteredValue: filter.sent ? [true] : [],

			onFilter: (_value, record) => record.sent === true,
		},
		{
			title: "Grade",
			dataIndex: "grade",
			key: "grade",
			width: 77,
		},

		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			width: 150,
			...getColumnSearchProps("location"),
		},
		{
			title: () => (
				<div>
					<img className="header-icon" src={bullet} />
					<div>
						Rock
						<Button
							icon={<CheckCircleFilled />}
							style={{
								backgroundColor: "#811b09",
								color: filter.greatRock ? "#b4d3b2" : "#55575a",
								border: "none",
								marginLeft: "5px",
							}}
							size="small"
							onClick={(e) =>
								setFilter({
									...filter,
									greatRock: !filter.greatRock,
								})
							}
						/>
					</div>
				</div>
			),
			dataIndex: "greatRock",
			key: "greatRock",
			width: 110,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
			filteredValue: filter.greatRock ? [true] : [],

			onFilter: (_value, record) => record.greatRock === true,
		},
		{
			title: () => (
				<div>
					<img className="header-icon" src={line} />
					<div>
						Line
						<Button
							icon={<CheckCircleFilled />}
							style={{
								backgroundColor: "#811b09",
								color: filter.uncontrived
									? "#b4d3b2"
									: "#55575a",
								border: "none",
								marginLeft: "5px",
							}}
							size="small"
							onClick={(e) =>
								setFilter({
									...filter,
									uncontrived: !filter.uncontrived,
								})
							}
						/>
					</div>
				</div>
			),
			dataIndex: "uncontrived",
			key: "uncontrived",
			inputType: "boolean",
			width: 110,
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
			filteredValue: filter.uncontrived ? [true] : [],

			onFilter: (_value, record) => record.uncontrived === true,
		},
		{
			title: () => (
				<div>
					<img className="header-icon" src={start} />
					<div>
						Start
						<Button
							icon={<CheckCircleFilled />}
							style={{
								backgroundColor: "#811b09",
								color: filter.obviousStart
									? "#b4d3b2"
									: "#55575a",
								border: "none",
								marginLeft: "5px",
							}}
							size="small"
							onClick={(e) =>
								setFilter({
									...filter,
									obviousStart: !filter.obviousStart,
								})
							}
						/>
					</div>
				</div>
			),
			dataIndex: "obviousStart",
			key: "obviousStart",
			inputType: "boolean",
			width: 110,
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
			filteredValue: filter.obviousStart ? [true] : [],

			onFilter: (_value, record) => record.obviousStart === true,
		},
		{
			title: () => (
				<div>
					<img className="header-icon" src={size} />
					<div>
						Height
						<Button
							icon={<CheckCircleFilled />}
							style={{
								backgroundColor: "#811b09",
								color: filter.tall ? "#b4d3b2" : "#55575a",
								border: "none",
								marginLeft: "5px",
							}}
							size="small"
							onClick={(e) =>
								setFilter({
									...filter,
									tall: !filter.tall,
								})
							}
						/>
					</div>
				</div>
			),
			dataIndex: "tall",
			key: "tall",
			width: 100,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
			filteredValue: filter.tall ? [true] : [],

			onFilter: (_value, record) => record.tall === true,
		},
		{
			title: () => (
				<div>
					<img className="header-icon" src={landing} />
					<div>
						Landing
						<Button
							icon={<CheckCircleFilled />}
							style={{
								backgroundColor: "#811b09",
								color: filter.flatLanding
									? "#b4d3b2"
									: "#55575a",
								border: "none",
								marginLeft: "5px",
							}}
							size="small"
							onClick={(e) =>
								setFilter({
									...filter,
									flatLanding: !filter.flatLanding,
								})
							}
						/>
					</div>
				</div>
			),
			dataIndex: "flatLanding",
			key: "flatLanding",
			width: 120,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
			filteredValue: filter.flatLanding ? [true] : [],

			onFilter: (_value, record) => record.flatLanding === true,
		},
	];
	return cols;
}

export default columns;
