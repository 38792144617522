import { onValue, ref } from "firebase/database";
import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/initFirebase";

const useGetUser = () => {
	const [data, setData] = useState({});
	const [user, loading] = useAuthState(auth);

	useEffect(() => {
		if (user) {
			onValue(ref(db, "/users/" + user.uid), (snapshot) => {
				const data = snapshot.val();
				if (data !== null) {
					setData({
						uid: user.uid,
						name: data.name,
						email: data.email,
					});
				}
			});
		}
	}, []);

	return [data];
};

export default useGetUser;
