import {
	HomeOutlined,
	MailOutlined,
	QuestionCircleOutlined,
	UserOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LongerLogo from "../assets/Top100logowide.png";
import Logo from "../logo.svg";
import { onAuthStateChanged } from "firebase/auth";
import { onValue, ref } from "firebase/database";
import { auth, db } from "../firebase/initFirebase";

export default function Sidebar({ closeBar }) {
	const [content, setContent] = useState("home");
	const page = window.location.pathname;
	const [activeUserName, setactiveUserName] = useState([]);
	useEffect(() => {
		const path = page.split("/");
		setContent(path[1] ? path[1] : "home");
		onAuthStateChanged(auth, (data) => {
			if (data) {
				//Sets Username if Logged in
				onValue(ref(db, "/users/" + data.uid), (snapshot) => {
					const data = snapshot.val();
					if (data !== null) {
						setactiveUserName(data.name);
					}
				});
			}
		});
	}, []);

	return (
		<div className={closeBar ? "sidebar close" : "sidebar"}>
			<Link to="/" className="logo">
				{closeBar ? (
					<img className="smallLogo" src={Logo} />
				) : (
					<img src={LongerLogo} />
				)}
			</Link>
			<ul class="side-menu">
				<li
					className={content === "home" ? "active" : null}
					onClick={() => setContent("home")}
				>
					<Link to={"/"}>
						<HomeOutlined className="bx" /> Home
					</Link>
				</li>
				<li
					className={content === "community" ? "active" : null}
					onClick={() => setContent("community")}
				>
					<Link to={"/community"}>
						<TeamOutlined className="bx" /> Community
					</Link>
				</li>
				<li
					className={content === "about" ? "active" : null}
					onClick={() => setContent("about")}
				>
					<Link to={"/about"}>
						<QuestionCircleOutlined className="bx" /> About
					</Link>
				</li>

				<li
					className={content === "contact" ? "active" : null}
					onClick={() => setContent("contact")}
				>
					<Link to={"/contact"}>
						<MailOutlined className="bx" /> Contact
					</Link>
				</li>
				<li className="phone-logout">
					{activeUserName ? (
						<Link to="/edit">
							<UserOutlined className="bx" />

							{activeUserName}
						</Link>
					) : (
						<Link to="/login">
							<UserOutlined className="bx" /> Login
						</Link>
					)}
				</li>
			</ul>
		</div>
	);
}
