import React, { useEffect, useState } from "react";

import "../styling/Community.css";
import { useParams } from "react-router-dom";
import { MainTable } from "./MainTable";

const CommunityTable = ({}) => {
	const { tableRef } = useParams();
	const [user, setUser] = useState();

	useEffect(() => {
		console.log(tableRef);
	}, []);

	return <MainTable tableUid={tableRef} user={user} />;
};

export default CommunityTable;
