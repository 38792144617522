import { EditExpandedRowRender } from "./EditExpandedRowRender";
import editingColumns from "./EditingColumns";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
	SortableContext,
	arrayMove,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button, Form, Input, Modal, Table, Upload } from "antd";
import { child, onValue, push, ref, update } from "firebase/database";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../firebase/initFirebase";
import "../styling/Table.css";
import { EditableCell } from "./EditableCell";
import { Row } from "./EditableRow";
import { ViewExpandedRowRender } from "./ViewExpandedRowRender";
import useGetTable from "../hooks/useGetTable";

const { TextArea } = Input;

const MainTable = ({
	addtrigger,
	writetrigger,
	tableRef,
	downloadTrigger,
	uploadTrigger,
	uid,
}) => {
	const [deleted, setDeleted] = useState(0);
	const [reloadTrigger, setReloadTrigger] = useState(0);
	const [expandedKey, setExpandedKey] = useState(null);
	const [dataSource, setDataSource] = useGetTable(reloadTrigger, tableRef);

	const [formRow] = Form.useForm();
	const [formModal] = Form.useForm();
	const [editingKey, setEditingKey] = useState("");
	const isEditing = (record) => record.key === editingKey;
	const [open, setOpen] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const current = new Date();
	const downloadRef = React.useRef(null);
	const uploadRef = React.useRef(null);
	// const [prvate, setPrivate] = useState(true);

	useEffect(() => {
		if (addtrigger) {
			handleAdd();
		}
	}, [addtrigger]);

	useEffect(() => {
		if (downloadTrigger) {
			downloadRef.current.click();
		}
	}, [downloadTrigger]);

	useEffect(() => {
		if (uploadTrigger) {
			uploadRef.current.click();
		}
	}, [uploadTrigger]);

	useEffect(() => {
		if (writetrigger) {
			setOpen(true);
			writetrigger = false;
		}
	}, [writetrigger]);

	const writeToDatabase = async (data, values) => {
		try {
			const newRef = push(child(ref(db), "changeLog")).key;
			let temp = data;
			temp.forEach((item) => {
				delete item.rank;
			});
			const updates = {};

			// add a changelog update if it exists
			if (values.change) {
				updates["/tables/" + tableRef + "/changeLog/" + newRef] = {
					change: values.change,
					date: `${
						current.getMonth() + 1
					}-${current.getDate()}-${current.getFullYear()}`,
				};
			}

			//add data to updates
			updates["/tables/" + tableRef + "/data/"] = temp;
			updates["/tables/" + tableRef + "/private/"] = dataSource.private;

			//set private in users dataset
			try {
				onValue(ref(db, "/users/" + uid + "/tables/"), (snapshot) => {
					const data = snapshot.val();
					if (data !== null) {
						for (let i = 0; i < data.length; i++) {
							if (data[i].tableRef === tableRef) {
								updates[
									"/users/" +
										uid +
										"/tables/" +
										i +
										"/private/"
								] = dataSource.private;
								break;
							}
						}
					}
				});
			} catch (e) {
				console.log(e);
			}

			// updates["/tables/" + ]
			update(ref(db), updates);
			setReloadTrigger((reloadTrigger) => reloadTrigger + 1);
		} catch (e) {
			console.log(e);
			alert("Save failed, download locally.");
		}

		setConfirmLoading(true);
		setTimeout(() => {
			setOpen(false);
			setConfirmLoading(false);
		}, 2000);
	};

	const handleEdit = (record) => {
		formRow.setFieldsValue({
			grade: "V",
			name: "",
			location: "",
			uncontrived: false,
			obviousStart: false,
			greatRock: false,
			flatLanding: false,
			tall: false,
			beautifulSetting: false,
			sent: "",
			description: "",
			link: "",
			fa: "",
			media: "",
			...record,
		});
		setEditingKey(record.key);
		setExpandedKey(record.key);
	};

	const handleCancel = () => {
		setEditingKey("");
	};

	const handleSave = async (key) => {
		try {
			const row = await formRow.validateFields();
			const newData = [...dataSource.data];
			const index = newData.findIndex((item) => key === item.key);
			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});
				setDataSource({ ...dataSource, data: newData });
				setEditingKey("");
				writeToDatabase(newData, "");
				console.log(dataSource, newData);
			} else {
				newData.push(row);
				setDataSource({ ...dataSource, data: newData });
				setEditingKey("");
				writeToDatabase(newData, "");
			}
		} catch (errInfo) {
			console.log("Validate Failed:", errInfo);
		}
	};

	const handleDelete = (key) => {
		var itemToDelete = dataSource.data.find((obj) => {
			return obj.key === key;
		});
		const newData = dataSource.data.filter((item) => item.key !== key);

		for (var i = 0; i < newData.length; i++) {
			if (itemToDelete.key < newData[i].key) {
				newData[i].key = newData[i].key - 1;
			}
		}
		setDeleted(deleted - 1);
		setDataSource({ ...dataSource, data: newData });
		writeToDatabase(newData, "");
	};

	const handleAdd = () => {
		const newRow = {
			key: parseInt(dataSource.data.length + 1),
			grade: "X",
			name: "X",
			location: "X",
			uncontrived: false,
			obviousStart: false,
			greatRock: false,
			flatLanding: false,
			tall: false,
			beautifulSetting: false,
			sent: false,
			link: "x",
			description: "x",
			fa: "x",
			media: 1,
		};
		setDataSource({ ...dataSource, data: [...dataSource.data, newRow] });
	};

	const onDragEnd = ({ active, over }) => {
		if (active.id !== over?.id) {
			let previous = dataSource.data;
			const activeIndex = previous.findIndex((i) => i.key === active.id);
			const overIndex = previous.findIndex((i) => i.key === over?.id);
			try {
				if (
					dataSource.data[activeIndex].key >
					dataSource.data[overIndex].key
				) {
					for (var i = overIndex; i < activeIndex; i++) {
						dataSource.data[i].key = dataSource.data[i].key + 1;
						dataSource.data[activeIndex].key =
							dataSource.data[overIndex].key - 1;
					}
				}
				if (
					dataSource.data[activeIndex].key <
					dataSource.data[overIndex].key
				) {
					for (var i = activeIndex + 1; i <= overIndex; i++) {
						dataSource.data[i].key = dataSource.data[i].key - 1;
						dataSource.data[activeIndex].key =
							dataSource.data[overIndex].key + 1;
					}
				}
			} catch (e) {
				console.log("failed: ", e);
			}
			const newData = arrayMove(previous, activeIndex, overIndex);
			setDataSource({
				...dataSource,
				data: newData,
			});
			writeToDatabase(newData, "");
		}
	};

	const mergedColumns = editingColumns(
		isEditing,
		handleSave,
		handleCancel,
		editingKey,
		handleEdit,
		handleDelete
	).map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: col.inputType === "boolean" ? "boolean" : "text",
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	const onExpand = (_, { key }) => {
		if (editingKey !== expandedKey) {
			expandedKey == key ? setExpandedKey(null) : setExpandedKey(key);
		}

		if (editingKey == key && expandedKey !== key) {
			setExpandedKey(key);
		}
	};

	const expandedRowRender = (record) => {
		return editingKey == record.key ? (
			<EditExpandedRowRender record={record} />
		) : (
			<ViewExpandedRowRender
				record={record}
				tableName={dataSource.tableName}
				tableUid={tableRef}
			/>
		);
	};

	let locale = {
		emptyText: (
			<span>
				<p>Whoopsie</p>
			</span>
		),
	};

	const closeModal = () => {
		setOpen(false);
	};

	const frontendUpload = (file, fileList) => {
		// parseTheFile (file);
		// fileList.forEach((f) => parseTheFile(f));
		var file = fileList[0];
		if (file.type.match(/text.*/)) {
			var reader = new FileReader();
			reader.onload = function (e) {
				var content = reader.result;
				var temp = JSON.parse(content);
				let i = 1;
				temp.forEach((element) => {
					isNaN(element.key) ||
					element.key === undefined ||
					element.isString
						? (element.key = i + 1)
						: (i = element.key);
					i++;
				});
				// Here the content has been read successfuly
				setDataSource({ ...dataSource, data: temp });
			};
			reader.readAsText(file);
		}
	};

	return (
		<div>
			<Modal
				title="Save Table"
				open={open}
				onOk={() => {
					formModal
						.validateFields()
						.then((values) => {
							formModal.resetFields();
							writeToDatabase(dataSource.data, values);
						})
						.catch((info) => {
							console.log("Validate Failed:", info);
						});
				}}
				confirmLoading={confirmLoading}
				onCancel={closeModal}
				centered
			>
				<Form
					form={formModal}
					name="form_in_modal"
					initalValues={"What did you change?"}
				>
					<Form.Item name="change">
						<TextArea
							rows={4}
							style={{ width: "350px" }}
							placeholder="What did you change? (Optional)"
						/>
					</Form.Item>
				</Form>
			</Modal>
			{dataSource.data && (
				<DndContext onDragEnd={onDragEnd}>
					<SortableContext
						items={dataSource.data.map((i) => i.key)}
						strategy={verticalListSortingStrategy}
					>
						<Form form={formRow} component={false}>
							<Table
								title={() => (
									<div
										style={{
											color: "white",
										}}
									>
										<p>{dataSource.tableName}</p>
										<label
											htmlFor="accept"
											className="private"
										>
											<input
												type="checkbox"
												className="private-check"
												name="private"
												checked={dataSource.private}
												onChange={(e) =>
													setDataSource({
														...dataSource,
														private:
															e.target.checked,
													})
												}
											/>
											Private
										</label>
									</div>
								)}
								scroll={{ y: "calc(100vh - 235px)" }}
								locale={locale}
								components={{
									body: {
										row: Row,
										cell: EditableCell,
									},
								}}
								sticky={true}
								rowKey="key"
								dataSource={dataSource.data}
								columns={mergedColumns}
								rowClassName="editable-row"
								pagination={false}
								expandable={{
									expandedRowRender,
									expandRowByClick: true,
									onExpand: onExpand,
									expandedRowKeys: [expandedKey],
								}}
								expandIconAsCell={false}
								expandIconColumnIndex={-1}
							/>
							{/* </div> */}
						</Form>
					</SortableContext>
				</DndContext>
			)}
			<Button
				style={{ display: "none" }}
				ref={downloadRef}
				href={`data:text/json;charset=utf-8,${encodeURIComponent(
					JSON.stringify(dataSource.data)
				)}`}
				download={dataSource.tableName + ".txt"}
			>
				<DownloadOutlined />
			</Button>

			<Upload
				beforeUpload={(file, fileList) => {
					frontendUpload(file, fileList);
				}}
			>
				<Button
					style={{ display: "none" }}
					ref={uploadRef}
					icon={<UploadOutlined />}
				></Button>
			</Upload>
		</div>
	);
};
export default MainTable;
