import React from "react";
import bullet from "../assets/bullet.png";
import landing from "../assets/landing.png";
import line from "../assets/line.png";
import size from "../assets/size.png";
import start from "../assets/start.png";
import "../styling/About.css";

const About = () => {
	return (
		<div id="about-container">
			<h1 id="about-header" style={{ marginTop: 10 }}>
				How to use this website
			</h1>

			<ul id="about-content">
				<li className="howto-bullet">
					Each row is expandable, click it to show a video, FA, and a
					short description for each climb.
				</li>{" "}
				<li className="howto-bullet">
					Clicking the buttons on the column headers will sort the
					table by that criteria. They are stackable, so if you want
					to find the climbs with the best rock and the best line,
					select those and only climbs that meet those criteria will
					be shown.
				</li>
				<li className="howto-bullet">
					The "Score" column is the community ranking for all the
					climbs. Use the button to sort by it. Each user that logs in
					has the ability to vote on each climb and whether you think
					it should be higher or lower on the list. An upvote is plus
					one point and a downvote is minus one point, the difference
					is the score.
				</li>
				<li className="howto-bullet">
					Clicking the "Comments" button right of the title shows the
					comments on the table. Only logged in users can add a
					comment.
				</li>
				<li className="howto-bullet">
					The Changelog is a record of every time a change has been
					made to the table, and what the change was.
				</li>
				<li className="howto-bullet">
					Create an account to make and share your own favorite (or
					least favorite (or anything really)) climbs.
				</li>
			</ul>

			<h1 id="about-header">Criteria</h1>
			<div className="criteria-content">
				<div className="about-bullet">
					<img className="about-icon" src={bullet} />
					<p>
						"Rock" refers to the quality of the rock. Longevity of a
						boulder is important, if it's gonna break or get
						polished in 10 years, it looses a star. The bullet in
						the icon refers to the idea that even if the holds were
						shot they would not be affected.
					</p>
				</div>{" "}
				<div className="about-bullet">
					<img className="about-icon" src={line} />
					<p>
						"Line" refers to the contrivance of a boulder. Does it
						go straight up? Does it traverse? Is it part of a link
						up? What we're looking for here is a boulder that starts
						at the bottom and ends at the top. The ideal for this
						category is that the line is quite literally the easiest
						way to get on top of the boulder, or to go even farther
						the only way to get there.{" "}
					</p>
				</div>
				<div className="about-bullet">
					<img className="about-icon" src={start} />
					<p>
						"Start" refers to the starting holds. An obvious start
						goes a long way. Would a sit start with forced or
						invisible starting holds be better than a stand start at
						a chest high jug? Probably not.{" "}
					</p>
				</div>
				<div className="about-bullet">
					<img className="about-icon" src={size} />
					<p>
						"Height" refers to, well, the height. A short boulder
						does not make a bad boulder, but most would agree that a
						taller line is more proud and inspiring. Taller is not
						necessarily better though. The sweet spot is 18 to 24
						feet, anything past that is a bit overkill, and more
						like a sport climb than a boulder.
					</p>
				</div>
				<div className="about-bullet">
					<img className="about-icon" src={landing} />
					<p>
						"Landing" is also pretty self explanatory. A nice flat
						landing can make a good boulder great.{" "}
					</p>
				</div>
			</div>
		</div>
	);
};

export default About;
