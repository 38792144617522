import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LongerLogo from "../../assets/Top100logowide.png";

import "../../styling/Layout.css";

export default function NavBar({
	setCloseBar,
	closeBar,
	activeUser,
	tables,
	tableName,
	setTableName,
	setTableRef,
}) {
	useEffect(() => {
		Object.values(tables).map((value) => {
			return console.log(value);
		});
		//Sets
	}, []);

	return (
		<nav>
			<Link to="/" class="phone-logo-container">
				<img class="phone-logo" src={LongerLogo} />
			</Link>
			<div
				class="menu-icon"
				onClick={() => {
					setCloseBar(!closeBar);
				}}
			>
				<MenuOutlined className="bx" />
			</div>
			<div className="nav-spacer"></div>

			{tables &&
				Object.values(tables).map((value) => {
					return (
						<li
							className={
								tableName === value.tableName
									? closeBar
										? "nav-table-item active "
										: "nav-table-item active hide"
									: "nav-table-item"
							}
						>
							<a
								style={{
									fontSize:
										value.tableName.length >= 10
											? 23 - value.tableName.length / 2
											: 20,
								}}
								onClick={() => {
									setTableName(value.tableName);
									setTableRef(value.tableRef);
								}}
							>
								{value.tableName}
							</a>
						</li>
					);
				})}

			<li className="logout">
				{activeUser ? (
					<Link className="logout" to="/edit">
						<UserOutlined className="bx logout-icon" />
						<div
							style={{
								whiteSpace: "nowrap",
								paddingRight: "10px",
							}}
						>
							{activeUser.name}
						</div>
					</Link>
				) : (
					<Link className="logout" to="/login">
						<UserOutlined className="bx logout-icon" />
						<div>Login</div>
					</Link>
				)}
			</li>
		</nav>
	);
}
