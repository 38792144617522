import React, { useEffect, useState } from "react";
import RowComments from "./RowComments";
import "../styling/ExpandedRow.css";
import { InstagramEmbed } from "react-social-media-embed";
export function ViewExpandedRowRender({ record, tableName, tableUid }) {
	const [src, setSrc] = useState("");
	useEffect(() => {
		if (record.media === 1) {
			setSrc("https://www.youtube.com/embed/" + record.link);
		} else if (record.media === 2) {
			setSrc("https://player.vimeo.com/video/" + record.link + "?");
		} else if (record.media === 3) {
			setSrc("https://www.instagram.com/p/" + record.link + "/");
		} else if (record.media === 4) {
			setSrc("https://kaya-app.kayaclimb.com/embed/" + record.link + "/");
		}
	});

	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
				}}
				className="expanded-content-container"
			>
				<div className="positioner">
					<div className="positioner-left">
						{record.link !== "undefined" ? (
							record.media === 3 ? (
								<InstagramEmbed
									url={src}
									width={328}
									height={460}
									style={{ marginRight: 30 }}
								/>
							) : (
								<div className="expanded-row-video">
									<iframe
										width="350"
										height="197"
										src={src}
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowFullScreen
										title="video"
									/>
								</div>
							)
						) : (
							<p></p>
						)}
						<div className="description-container">
							{record.fa && (
								<p className="fa">First Ascent: {record.fa}</p>
							)}

							<p className="description">{record.description}</p>
						</div>
					</div>
					<RowComments
						rowIndex={record.key - 1}
						tableName={tableName}
						tableUid={tableUid}
					/>
				</div>
			</div>
		</>
	);
}
