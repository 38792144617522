import { EnterOutlined } from "@ant-design/icons";
import React from "react";
import "../styling/Comment.css";

const Comment = ({
	comment,
	commentKey,
	activeComment,
	setActiveComment,
	level,
}) => {
	return (
		<div
			className={
				activeComment == commentKey ? "comment active" : "comment"
			}
			style={{ marginLeft: level * 40 }}
		>
			<div className="comment-header">
				<p className="comment-author">{comment.authorUsername}</p>

				<p className="comment-date">{comment.date}</p>
			</div>
			<p className="comment-body">{comment.content}</p>
			<button
				className="comment-reply"
				onClick={() => {
					// replyToComment();
					commentKey == activeComment
						? setActiveComment("")
						: setActiveComment(commentKey);
				}}
			>
				{activeComment == commentKey ? (
					"Replying"
				) : (
					<div>
						<EnterOutlined style={{ transform: "scaleX(-1)" }} />
						Reply
					</div>
				)}
			</button>
		</div>
	);
};

export default Comment;
