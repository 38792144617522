import React from "react";
import { EditableCell } from "./EditableCell";

export function EditExpandedRowRender({ record }) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				style={{
					margin: "30px",
					marginTop: "10px",
				}}
			>
				<p
					style={{
						color: "white",
						textAlign: "left",
						fontWeight: "900",
						marginTop: "10px",
						marginBottom: "5px",
					}}
				>
					First Ascent:
				</p>
				<EditableCell
					editing={true}
					dataIndex={"fa"}
					inputType={"text"}
					record={record}
				/>
				<p
					style={{
						color: "white",
						textAlign: "left",
						fontWeight: "900",
						marginTop: "10px",
						marginBottom: "5px",
					}}
				>
					Video Id:
				</p>
				<EditableCell
					editing={true}
					dataIndex={"link"}
					inputType={"text"}
					record={record}
				/>
			</div>
			<div>
				<EditableCell
					editing={true}
					dataIndex={"media"}
					inputType={"radio"}
					record={record}
				/>
			</div>
			<div
				style={{
					margin: "30px",
					marginTop: "10px",
				}}
			>
				<p
					style={{
						color: "white",
						textAlign: "left",
						fontWeight: "900",
						marginTop: "0",
					}}
				>
					Description:
				</p>
				<EditableCell
					editing={true}
					dataIndex={"description"}
					inputType={"textArea"}
					record={record}
				/>
			</div>
		</div>
	);
}
