import { onAuthStateChanged } from "firebase/auth";
import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import {
	Outlet,
	useLocation,
	useResolvedPath,
	useRouteMatch,
} from "react-router-dom";
import { auth, db } from "../firebase/initFirebase";
import "../styling/Layout.css";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";
import { useAuthState } from "react-firebase-hooks/auth";

const Lay = () => {
	const [closeBar, setCloseBar] = useState(true);
	const [activeUser, setActiveUser] = useState();
	const [tableRef, setTableRef] = useState("");
	const [tableName, setTableName] = useState("");
	const [adminUid, setAdminUid] = useState("BIM6Jt3LVwd3tn6DNYWjgGcF5hc2");
	const [tables, setTables] = useState([]);

	useEffect(() => {
		onAuthStateChanged(auth, (data) => {
			if (data) {
				let tempUid = data.uid;
				onValue(ref(db, "/users/" + data.uid), (snapshot) => {
					const data = snapshot.val();
					console.log(data);
					if (data !== null) {
						setActiveUser({
							name: data.name,
							email: data.email,
							uid: tempUid,
						});
					}
				});
			}
		});

		onValue(ref(db, "/users/" + adminUid), (snapshot) => {
			const data = snapshot.val();

			if (data !== null) {
				let temp = [];
				for (let i = 0; i < data.tables.length; i++) {
					if (
						data.tables[i].private != null &&
						!data.tables[i].private
					) {
						temp.push(data.tables[i]);
					}
				}
				setTables(temp);
				data.tables && setTableName(data.tables[0].tableName);
				data.tables && setTableRef(data.tables[0].tableRef);
			}
		});
	}, []);

	return (
		<body>
			<Sidebar closeBar={closeBar} />
			<div class="content">
				<NavBar
					setCloseBar={setCloseBar}
					closeBar={closeBar}
					activeUser={activeUser}
					tables={tables}
					tableName={tableName}
					setTableName={setTableName}
					setTableRef={setTableRef}
				/>
				<Outlet context={[tableRef]} />
			</div>
		</body>
	);
};
export default Lay;
