import { Table, notification } from "antd";
import { child, onValue, push, ref, update } from "firebase/database";
import React, { useEffect, useState } from "react";
import { db } from "../firebase/initFirebase";
import "../styling/RowComments.css";
import Comment from "./Comment.jsx";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/initFirebase";

const RowComments = ({ tableName, tableUid, rowIndex }) => {
	const [api, contextHolder] = notification.useNotification();

	const [comment, setComment] = useState("");
	const [commentList, setCommentList] = useState();
	const current = new Date();
	const [activeComment, setActiveComment] = useState("");
	const [user, loading] = useAuthState(auth);

	const openNotification = (placement, type) => {
		api.info({
			message: type === "vote" ? "Login to vote" : "Login to comment",
			className: "notification",
			placement,
			style: {
				color: "white",
			},
			icon: (
				<ExclamationCircleFilled
					style={{
						color: "#d4371c",
					}}
				/>
			),
		});
	};

	useEffect(() => {
		// console.log(uid, tableName);
		onValue(
			ref(db, "/tables/" + tableUid + "/data/" + rowIndex + "/comments/"),
			(snapshot) => {
				const data = snapshot.val();
				if (data !== null) {
					setCommentList(data);
				} else {
					setCommentList([0]);
				}
			}
		);
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!user) {
			return;
		}
		const newRef = push(
			child(
				ref(db),
				"/tables/" + tableUid + "/data/" + rowIndex + "/comments/"
			)
		).key;
		var username = "";
		onValue(ref(db, "/users/" + user.uid), (snapshot) => {
			const data = snapshot.val();
			if (data !== null) {
				username = data.name;
			}
		});

		const updates = {};
		updates[
			(db,
			"/tables/" + tableUid + "/data/" + rowIndex + "/comments/" + newRef)
		] = {
			content: comment,
			authorUid: user.uid,
			authorUsername: username,
			date: `${
				current.getMonth() + 1
			}-${current.getDate()}-${current.getFullYear()}`,
			parentComment: activeComment !== "" ? activeComment : null,
		};

		update(ref(db), updates);
		setComment("");
		setActiveComment("");
	};

	const DisplayContentList = ({ parent, level }) => {
		level = level + 1;
		console.log(commentList);
		return (
			Object.keys(commentList)
				//filters out comments that don't have the given parent
				.filter((key) => commentList[key].parentComment == parent)
				//loops through filtered comments
				.map((key) => {
					return (
						<>
							<Comment
								comment={commentList[key]}
								commentKey={key}
								activeComment={activeComment}
								setActiveComment={setActiveComment}
								level={level}
							/>

							<DisplayContentList parent={key} level={level} />
						</>
					);
				})
		);
	};

	return (
		<div className="row-commentSection-container">
			{contextHolder}
			<div className="comments-container">
				{commentList && commentList[0] !== 0 ? (
					<DisplayContentList parent={null} level={0} />
				) : (
					<div className="empty-comment-section">
						No Comments Yet!
					</div>
				)}
			</div>

			<form className="comment-input-container" onSubmit={handleSubmit}>
				<textarea
					placeholder="Type your comment here"
					type="text"
					value={comment}
					className="comment-input"
					onChange={(e) => {
						if (user) {
							e.stopPropagation();
							setComment(e.target.value);
						} else {
							e.stopPropagation();
							openNotification("top", "comment");
						}
					}}
				/>
				<input
					className="comment-post-button"
					type="submit"
					value="Post"
				/>
			</form>
		</div>
	);
};

export default RowComments;
