import React from "react";
import {
	SortableContext,
	arrayMove,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { MenuOutlined } from "@ant-design/icons";
import { CSS } from "@dnd-kit/utilities";

export const Row = ({ children, ...props }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id: props["data-row-key"],
	});
	const style = {
		...props.style,
		transform: CSS.Transform.toString(
			transform && { ...transform, scaleY: 1 }
		)?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
		transition,
		...(isDragging
			? {
					position: "relative",
					zIndex: 2,
			  }
			: {}),
	};
	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(children, (child) => {
				if (child.key === "sort") {
					return React.cloneElement(child, {
						children: (
							<MenuOutlined
								ref={setActivatorNodeRef}
								style={{
									touchAction: "none",
									cursor: "move",
								}}
								{...listeners}
							/>
						),
					});
				}

				return child;
			})}
		</tr>
	);
};
