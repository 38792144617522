import { Table } from "antd";
import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { db } from "../firebase/initFirebase";
import "../styling/Changelog.css";

const ChangeLog = ({
	setOpenComments,
	setOpenChangeLog,
	tableName,
	tableUid,
}) => {
	const [changeLog, setChangeLog] = useState([]);
	useEffect(() => {
		onValue(
			ref(db, "changeLog"),
			(snapshot) => {
				const data = snapshot.val();
				if (data !== null) {
					setChangeLog(data);
				}
			},
			{
				onlyOnce: true,
			}
		);
	}, []);
	let locale = {
		emptyText: (
			<div id="changelog-container">
				{Object.values(changeLog)
					.reverse()
					.map((data, i) => (
						<div key={i} id="changelog-item">
							<p id="changelog-header">{data.date}</p>
							<p id="changelog-content">{data.change}</p>
						</div>
					))}
			</div>
		),
	};
	return (
		<div className="table__container">
			<Table
				style={{
					width: "calc(100vw - 5vw)",
					height: "calc(100vh - 10vh)",
				}}
				locale={locale}
				title={() => (
					<div className="table-title">
						<div className="invisible-title-box"></div>
						<button
							className="title-name-button"
							onClick={() => {
								setOpenComments(false);
								setOpenChangeLog(false);
							}}
						>
							{tableName}
						</button>
						<div className="title-button-container">
							<button
								className="title-button"
								onClick={() => {
									setOpenComments(true);
									setOpenChangeLog(false);
								}}
							>
								Comments
							</button>
							<button
								className="title-button"
								onClick={() => {
									setOpenChangeLog(true);
									setOpenComments(false);
								}}
							>
								Changelog
							</button>
						</div>
					</div>
				)}
			/>
		</div>
	);
};

export default ChangeLog;
