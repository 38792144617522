import { Table } from "antd";
import React from "react";
export function ViewingTable({
	tableName,
	setOpenComments,
	setOpenChangeLog,
	locale,
	expandedKey,
	dataSource,
	expandedRowRender,
	onExpand,
	defaultColumns,
}) {
	return (
		<Table
			title={() => (
				<div className="table-title">
					<div className="invisible-title-box"></div>
					<div className="table-titlename">{tableName}</div>
					<div className="title-button-container">
						<button
							className="title-button"
							onClick={() => {
								setOpenComments(true); // setOpenChangeLog(false);
							}}
						>
							Comments
						</button>
						<button
							className="title-button"
							onClick={() => {
								setOpenChangeLog(true);
								setOpenComments(false);
							}}
						>
							Changelog
						</button>
					</div>
				</div>
			)}
			scroll={{
				y: "57vh",
			}}
			locale={locale}
			className="table"
			rowKey="key"
			dataSource={dataSource}
			columns={defaultColumns}
			rowClassName="editable-row"
			pagination={false}
			// sticky={true}
			// onChange={viewColumns.handleChange()}
			expandable={{
				expandedRowRender,
				expandRowByClick: true,
				onExpand: onExpand,
				expandedRowKeys: [expandedKey],
				rowExpandable: (record) =>
					record.description || record.link === "",
			}}
			expandIconAsCell={false}
			expandIconColumnIndex={-1}
		/>
	);
}
