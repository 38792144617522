import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { Popconfirm, Typography } from "antd";
import React from "react";

function editingColumns(
	isEditing,
	handleSave,
	handleCancel,
	editingKey,
	handleEdit,
	handleDelete
) {
	const cols = [
		{
			key: "sort",
			width: 70,
			render: () => {
				<div onClick={(e) => e.stopPropagation()}></div>;
			},
		},
		{
			title: "Rank",
			dataIndex: "key",
			key: "key",
			width: 80,
		},
		{
			title: "Sent",
			dataIndex: "sent",
			key: "sent",
			editable: true,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
		},
		{
			title: "Grade",
			dataIndex: "grade",
			key: "grade",
			editable: true,
			width: 80,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			editable: true,
			width: 120,
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			editable: true,
			width: 120,
		},
		{
			title: "Rock",
			dataIndex: "greatRock",
			key: "greatRock",
			editable: true,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
		},
		{
			title: "Line",
			dataIndex: "uncontrived",
			key: "uncontrived",
			editable: true,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
		},
		{
			title: "Start",
			dataIndex: "obviousStart",
			key: "obviousStart",
			editable: true,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
		},

		{
			title: "Height",
			dataIndex: "tall",
			key: "tall",
			editable: true,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
		},
		{
			title: "Landing",
			dataIndex: "flatLanding",
			key: "flatLanding",
			editable: true,
			inputType: "boolean",
			render: (text) =>
				text ? (
					<CheckCircleFilled className="check" />
				) : (
					<CloseCircleOutlined className="uncheck" />
				),
		},
		// {
		// 	title: "Scene",
		// 	dataIndex: "beautifulSetting",
		// 	key: "beautifulSetting",
		// 	editable: true,
		// 	inputType: "boolean",
		// 	render: (text) =>
		// 		text ? (
		// 			<CheckCircleFilled className="check" />
		// 		) : (
		// 			<CloseCircleOutlined className="uncheck" />
		// 		),
		// },
		{
			dataIndex: "operation",
			width: 80,
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<span>
						<Typography.Link
							onClick={(e) => {
								e.stopPropagation();
								handleSave(record.key);
							}}
							style={{
								marginRight: 8,
							}}
						>
							Save
						</Typography.Link>
						<Popconfirm
							title="Sure to cancel?"
							onConfirm={handleCancel}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<a>Cancel</a>
						</Popconfirm>
					</span>
				) : (
					<span>
						<Typography.Link
							disabled={editingKey !== ""}
							onClick={(e) => {
								e.stopPropagation();
								handleEdit(record);
							}}
						>
							Edit
						</Typography.Link>
						<Popconfirm
							title="Sure to delete?"
							onConfirm={(e) => {
								e.stopPropagation();
								handleDelete(record.key);
							}}
						>
							<a> Delete</a>
						</Popconfirm>
					</span>
				);
			},
		},
	];
	return cols;
}

export default editingColumns;
