import { Table } from "antd";
import { child, onValue, push, ref, update } from "firebase/database";
import React, { useEffect, useState } from "react";
import { db } from "../firebase/initFirebase";
import "../styling/Comments.css";
import Comment from "./Comment.jsx";
const CommentSection = ({
	setOpenComments,
	setOpenChangeLog,
	user,
	tableName,
	tableUid,
	openNotification,
}) => {
	const [comment, setComment] = useState("");
	const [commentList, setCommentList] = useState();
	const current = new Date();
	const [activeComment, setActiveComment] = useState("");

	useEffect(() => {
		// console.log(uid, tableName);
		onValue(ref(db, "/tables/" + tableUid + "/comments/"), (snapshot) => {
			const data = snapshot.val();
			if (data !== null) {
				setCommentList(data);
			} else {
				setCommentList([0]);
			}
		});
	}, [tableName]);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!user) {
			return;
		}
		const newRef = push(child(ref(db), "comments")).key;
		var username = "";
		onValue(ref(db, "/users/" + user), (snapshot) => {
			const data = snapshot.val();
			if (data !== null) {
				username = data.name;
			}
		});
		const updates = {};
		updates["/tables/" + tableUid + "/comments/" + newRef] = {
			content: comment,
			authorUid: user,
			authorUsername: username,
			date: `${
				current.getMonth() + 1
			}-${current.getDate()}-${current.getFullYear()}`,
			parentComment: activeComment !== "" ? activeComment : null,
		};
		update(ref(db), updates);
		setComment("");
		setActiveComment("");
	};

	const DisplayContentList = ({ parent, level }) => {
		level = level + 1;
		return (
			Object.keys(commentList)
				//filters out comments that don't have the given parent
				.filter((key) => commentList[key].parentComment == parent)
				//loops through filtered comments
				.map((key) => {
					return (
						<>
							<Comment
								comment={commentList[key]}
								commentKey={key}
								activeComment={activeComment}
								setActiveComment={setActiveComment}
								level={level}
							/>

							<DisplayContentList parent={key} level={level} />
						</>
					);
				})
		);
	};

	let locale = {
		emptyText: (
			<div className="commentSection-container">
				<div className="comments-container">
					{commentList && commentList[0] !== 0 ? (
						<DisplayContentList parent={null} level={0} />
					) : (
						<div className="empty-comment-section">
							No Comments Yet!
						</div>
					)}
				</div>

				<form
					className="comment-input-container"
					onSubmit={handleSubmit}
				>
					<textarea
						placeholder="Type your comment here"
						type="text"
						value={comment}
						className="comment-input"
						onChange={(e) => {
							if (user) {
								e.stopPropagation();
								setComment(e.target.value);
							} else {
								e.stopPropagation();
								openNotification("top", "comment");
							}
						}}
					/>
					<input
						className="comment-post-button"
						type="submit"
						value="Post"
					/>
				</form>
			</div>
		),
	};
	return (
		<div className="table__container">
			<Table
				locale={locale}
				className="table"
				scroll={{
					y: "75vh",
				}}
				title={() => (
					<div className="table-title">
						<div className="invisible-title-box"></div>
						<button
							className="title-name-button"
							onClick={() => {
								setOpenComments(false);
								setOpenChangeLog(false);
							}}
						>
							{tableName}
						</button>
						<div className="title-button-container">
							<button
								className="title-button"
								onClick={() => {
									setOpenComments(true);
									setOpenChangeLog(false);
								}}
							>
								Comments
							</button>
							<button
								className="title-button"
								onClick={() => {
									setOpenChangeLog(true);
									setOpenComments(false);
								}}
							>
								Changelog
							</button>
						</div>
					</div>
				)}
			/>
		</div>
	);
};

export default CommentSection;
