import { onValue, ref } from "firebase/database";
import { useState, useEffect } from "react";
import { db } from "../firebase/initFirebase";

const useGetTable = (reloadTrigger, tableUid) => {
	const [dataSource, setdataSource] = useState({});
	useEffect(() => {
		try {
			if (tableUid) {
				console.log(tableUid);
				onValue(ref(db, "/tables/" + tableUid), (snapshot) => {
					const data = snapshot.val();
					if (data !== null) {
						setdataSource(data);
					} else {
						setdataSource({
							...dataSource,
							data: [
								{
									key: 1,
									grade: "X",
									name: "X",
									location: "X",
									uncontrived: false,
									obviousStart: false,
									greatRock: false,
									flatLanding: false,
									tall: false,
									beautifulSetting: false,
									sent: false,
									description: "X",
									link: "X",
									fa: "X",
									media: "X",
								},
								{
									key: 2,
									grade: "X",
									name: "X",
									location: "X",
									uncontrived: false,
									obviousStart: false,
									greatRock: false,
									flatLanding: false,
									tall: false,
									beautifulSetting: false,
									sent: false,
									description: "X",
									link: "X",
									fa: "X",
									media: "X",
								},
								{
									key: 3,
									grade: "X",
									name: "X",
									location: "X",
									uncontrived: false,
									obviousStart: false,
									greatRock: false,
									flatLanding: false,
									tall: false,
									beautifulSetting: false,
									sent: false,
									description: "X",
									link: "X",
									fa: "X",
									media: "X",
								},
								{
									key: 4,
									grade: "X",
									name: "X",
									location: "X",
									uncontrived: false,
									obviousStart: false,
									greatRock: false,
									flatLanding: false,
									tall: false,
									beautifulSetting: false,
									sent: false,
									description: "X",
									link: "X",
									fa: "X",
									media: "X",
								},
								{
									key: 5,
									grade: "X",
									name: "X",
									location: "X",
									uncontrived: false,
									obviousStart: false,
									greatRock: false,
									flatLanding: false,
									tall: false,
									beautifulSetting: false,
									sent: false,
									description: "X",
									link: "X",
									fa: "X",
									media: "X",
								},
							],
						});
					}
				});
			}
		} catch (e) {
			console.log(e);
		}
	}, [reloadTrigger, tableUid]);

	return [dataSource, setdataSource];
};

export default useGetTable;
