import React, { useState, useEffect } from "react";
import "../styling/Contact.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [showError, setShowError] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		// Your EmailJS service ID, template ID, and Public Key
		const serviceId = "service_djm0eh7";
		const templateId = "template_8l3y2cf";
		const publicKey = "3PZ0xzvbGwy3zxijo";

		// Create a new object that contains dynamic template params
		const templateParams = {
			from_name: name,
			from_email: email,
			// to_name: "Web Wizard",
			message: message,
		};

		// Send the email using EmailJS
		emailjs
			.send(serviceId, templateId, templateParams, publicKey)
			.then((response) => {
				setShowConfirmation(true);
				setName("");
				setEmail("");
				setMessage("");
			})
			.catch((error) => {
				setShowError(true);
			});
	};

	return (
		<div id="contact-container">
			<form className="form" onSubmit={handleSubmit}>
				<h1 id="contact-header">Contact Us</h1>

				<p id="contact-item">
					Hit us up, we'd love to hear any questions or concerns, bugs
					or feature ideas, or whatever else. If you have encountered
					a bug, please be as descriptive as possible about what
					happened and what you were doing before the bug happened.
				</p>
				<div id="contact-item">
					<input
						placeholder="Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div id="contact-item">
					<input
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div id="contact-item">
					<textarea
						placeholder="Message"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					></textarea>
				</div>
				<div id="contact-button">
					<button type="submit">Submit</button>
					{showError && (
						<p className="email-error">Email couldn't be sent</p>
					)}
					{showConfirmation && (
						<p className="email-confirmation">
							Email sent successfully
						</p>
					)}
				</div>
			</form>
		</div>
	);
};

export default Contact;
