import {
	DownloadOutlined,
	MenuOutlined,
	MinusCircleOutlined,
	PlusCircleOutlined,
	QuestionCircleOutlined,
	SaveOutlined,
	UploadOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Form, Input, Layout, Modal, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { logout } from "../firebase/initFirebase";
import "../styling/Layout.css";
import EditMainTable from "./EditMainTable";
import HowTo from "./HowTo";
import useFitText from "use-fit-text";

import { child, onValue, push, ref, update, remove } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import LongerLogo from "../assets/Top100logowide.png";
import { auth, db } from "../firebase/initFirebase";

const EditLay = () => {
	const navigate = useNavigate();
	const [user, loading] = useAuthState(auth);
	const [userData, setUserData] = useState([]);
	const [addtrigger, setaddtrigger] = useState(0);
	const [writetrigger, setwritetrigger] = useState(false);
	const [downloadTrigger, setDownloadTrigger] = useState(false);
	const [uploadTrigger, setUploadTrigger] = useState(false);
	const [tableName, setTableName] = useState("");
	const [tableRef, setTableRef] = useState("");
	const [form] = Form.useForm();
	const [open, setOpen] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [closeBar, setCloseBar] = useState(true);

	useEffect(() => {
		setwritetrigger(false);
		setDownloadTrigger(false);
		setUploadTrigger(false);
	}, [writetrigger, downloadTrigger, uploadTrigger]);

	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/");

		onValue(ref(db, "/users/" + user.uid), (snapshot) => {
			const data = snapshot.val();
			if (data !== null) {
				setUserData(data);
				data.tables && setTableName(data.tables[0].tableName);
				data.tables && setTableRef(data.tables[0].tableRef);
			}
		});
	}, [user, loading]);

	const deleteTable = () => {
		try {
			remove(ref(db, "/tables/" + tableRef));

			let newList = [];
			onValue(ref(db, "/users/" + user.uid + "/tables/"), (snapshot) => {
				const data = snapshot.val();
				if (data !== null) {
					newList = data;
				}
			});
			newList = newList.filter((item) => {
				return item.tableRef !== tableRef;
			});

			const updates = {};
			updates["/users/" + user.uid + "/tables/"] = newList;
			update(ref(db), updates);
		} catch (e) {
			console.log(e);
		}
	};

	const createNewTable = (name) => {
		const updates = {};
		const tableRef = push(child(ref(db), "table")).key;

		updates["/tables/" + tableRef + "/data/"] = [
			{
				key: 1,
				grade: "X",
				name: "X",
				location: "X",
				uncontrived: false,
				obviousStart: false,
				greatRock: false,
				flatLanding: false,
				tall: false,
				beautifulSetting: false,
				sent: false,
				description: "X",
				link: "X",
				fa: "X",
				media: "X",
			},
			{
				key: 2,
				grade: "X",
				name: "X",
				location: "X",
				uncontrived: false,
				obviousStart: false,
				greatRock: false,
				flatLanding: false,
				tall: false,
				beautifulSetting: false,
				sent: false,
				description: "X",
				link: "X",
				fa: "X",
				media: "X",
			},
			{
				key: 3,
				grade: "X",
				name: "X",
				location: "X",
				uncontrived: false,
				obviousStart: false,
				greatRock: false,
				flatLanding: false,
				tall: false,
				beautifulSetting: false,
				sent: false,
				description: "X",
				link: "X",
				fa: "X",
				media: "X",
			},
			{
				key: 4,
				grade: "X",
				name: "X",
				location: "X",
				uncontrived: false,
				obviousStart: false,
				greatRock: false,
				flatLanding: false,
				tall: false,
				beautifulSetting: false,
				sent: false,
				description: "X",
				link: "X",
				fa: "X",
				media: "X",
			},
			{
				key: 5,
				grade: "X",
				name: "X",
				location: "X",
				uncontrived: false,
				obviousStart: false,
				greatRock: false,
				flatLanding: false,
				tall: false,
				beautifulSetting: false,
				sent: false,
				description: "X",
				link: "X",
				fa: "X",
				media: "X",
			},
		];
		updates["/tables/" + tableRef + "/private/"] = true;
		updates["/tables/" + tableRef + "/owner/"] = user.uid;
		updates["/tables/" + tableRef + "/tableName/"] = name;
		updates["/tables/" + tableRef + "/tableRef/"] = tableRef;
		updates["/tables/" + tableRef + "/userName/"] = userData.name;

		userData.tables
			? (updates["/users/" + user.uid + "/tables/"] = [
					...userData.tables,
					{ tableRef: tableRef, tableName: name, private: true },
			  ])
			: (updates["/users/" + user.uid + "/tables/"] = [
					{ tableRef: tableRef, tableName: name, private: true },
			  ]);
		update(ref(db), updates);
	};

	const closeModal = () => {
		console.log("Clicked cancel button");
		setOpen(false);
	};

	return (
		<body>
			<Modal
				title="Name Your New Table"
				open={open}
				onOk={() => {
					form.validateFields()
						.then((values) => {
							form.resetFields();
							createNewTable(values.change);
							closeModal();
						})
						.catch((info) => {
							console.log("Validate Failed:", info);
						});
				}}
				confirmLoading={confirmLoading}
				onCancel={closeModal}
				centered
				style={{
					width: "400px",
					zindex: 9999,
				}}
			>
				<Form form={form} name="form_in_modal">
					<Form.Item name="change">
						<Input style={{ width: "350px" }} />
					</Form.Item>
				</Form>
			</Modal>
			<div className={closeBar ? "sidebar close" : "sidebar"}>
				<Link to="/" className="logo">
					{closeBar ? (
						<img
							className="smallLogo"
							src="logo.svg"
							alt="Top 100"
						/>
					) : (
						<img src={LongerLogo} />
					)}
				</Link>
				{tableName && (
					<ul className="side-menu">
						<li>
							<a
								onClick={() => {
									setwritetrigger(
										(writetrigger) => !writetrigger
									);
								}}
							>
								<SaveOutlined className="bx" />
								Save Table
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setaddtrigger(
										(addtrigger) => addtrigger + 1
									);
								}}
							>
								<PlusCircleOutlined className="bx" />
								Add Row
							</a>
						</li>
						<div className="has-border"></div>
						<li>
							<a
								onClick={() => {
									setDownloadTrigger(
										(downloadTrigger) => !downloadTrigger
									);
								}}
							>
								<DownloadOutlined className="bx" />
								Download
							</a>
						</li>
						<li>
							<a
								onClick={() => {
									setUploadTrigger(
										(uploadTrigger) => !uploadTrigger
									);
								}}
							>
								<UploadOutlined className="bx" />
								Upload
							</a>
						</li>

						<li className="delete">
							<Popconfirm
								title="Sure to delete?"
								onConfirm={() => {
									deleteTable();
								}}
								icon=""
								style={{
									backgroundColor: "#55575a",
									zIndex: 3,
								}}
							>
								<a>
									{" "}
									<MinusCircleOutlined className="bx" />{" "}
									Delete Table
								</a>
							</Popconfirm>
						</li>
					</ul>
				)}
			</div>
			<div className="content">
				<nav>
					<div className="menu-icon">
						<a
							onClick={() => {
								setCloseBar(!closeBar);
							}}
						>
							<MenuOutlined className="bx" />
						</a>
					</div>
					<li className="new-table">
						<a
							onClick={() => {
								setOpen(true);
							}}
						>
							<PlusCircleOutlined className="bx" /> New Table
						</a>
					</li>
					<div className="scroll-div">
						{userData.tables &&
							Object.values(userData.tables).map((value) => {
								return (
									<li
										className={
											tableName === value.tableName
												? "active"
												: null
										}
										key={value.tableName}
									>
										<a
											style={{
												fontSize:
													value.tableName.length >= 10
														? 23 -
														  value.tableName
																.length /
																1.5
														: 20,
											}}
											onClick={() => {
												setTableName(value.tableName);
												setTableRef(value.tableRef);
											}}
										>
											{value.tableName}
										</a>
									</li>
								);
							})}
					</div>
					<div className="nav-section">
						<li
							className={
								tableName === "" ? "how-to active" : "how-to"
							}
						>
							<a
								onClick={() => {
									setTableName("");
								}}
							>
								<QuestionCircleOutlined className="bx" /> How To
							</a>
						</li>
						<li className="logout">
							<a
								onClick={() => {
									logout();
								}}
							>
								<UserOutlined className="bx" /> Logout
							</a>
						</li>
					</div>
				</nav>

				<div className="main">
					{tableName === "" ? (
						<HowTo />
					) : (
						<EditMainTable
							addtrigger={addtrigger}
							writetrigger={writetrigger}
							tableRef={tableRef}
							downloadTrigger={downloadTrigger}
							uploadTrigger={uploadTrigger}
							uid={user ? user.uid : ""}
						/>
					)}
				</div>
			</div>
		</body>
	);
};
export default EditLay;
