import React, { useEffect, useState } from "react";
import { ref, onValue, equalTo } from "firebase/database";

import { db } from "../firebase/initFirebase";
import MiniTable from "./MiniTable";

import "../styling/Community.css";
import { Link, Outlet } from "react-router-dom";
const Community = ({}) => {
	const [tables, setTables] = useState([]);

	useEffect(() => {
		onValue(ref(db, "/tables/"), (snapshot) => {
			const data = snapshot.val();
			let temp = [];
			Object.values(data).map((table) => {
				!table.private &
					(table.owner !== "BIM6Jt3LVwd3tn6DNYWjgGcF5hc2") &&
					temp.push(table);
			});
			setTables(temp);
			console.log(temp);
		});
	}, []);

	return (
		// <div className="community-container">
		// 	<div className="underConstruction">Under Construction</div>
		// </div>
		<>
			<div className="community-container">
				<div className="table-list-container">
					<h1 className="table-list-container-title">Recents</h1>
					<div className="table-list">
						{Object.values(tables)
							/* .slice(0, 7) */
							.map((table, i) => {
								/* return <MiniTable table={table} />; */
								console.log(table);
								return (
									<Link to={`/community/${table.tableRef}`}>
										<div className="community-link">
											<a className="community-row">
												{table.tableName}
												<br />
												{table.userName}
											</a>
										</div>
									</Link>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
};

export default Community;
