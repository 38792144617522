import { Checkbox, Form, Input, Radio, Space } from "antd";
import React, { useState } from "react";
import "../styling/Table.css";

const { TextArea } = Input;

export const EditableCell = ({
	editing,
	dataIndex,
	title,
	inputType,
	record,
	index,
	children,
	...restProps
}) => {
	const [radioValue, setRadioValue] = useState(1);

	const onRadioChange = (e) => {
		console.log("radio checked", e.target.value);
		setRadioValue(e.target.value);
	};

	const inputNode =
		inputType === "boolean" ? (
			<Checkbox value={record[dataIndex]} />
		) : inputType === "textArea" ? (
			<TextArea
				rows={4}
				style={{
					width: "350px",
				}}
			/>
		) : inputType === "radio" ? (
			<Radio.Group onChange={onRadioChange} value={radioValue}>
				<Space direction="vertical">
					<Radio value={1}>Youtube</Radio>
					<Radio value={2}>Vimeo</Radio>
					<Radio value={3}>Insta</Radio>
					<Radio value={4}>Kaya</Radio>
				</Space>
			</Radio.Group>
		) : (
			<Input />
		);
	return (
		<td {...restProps}>
			{editing ? (
				inputType === "boolean" ? (
					<Form.Item
						name={dataIndex}
						valuePropName={dataIndex ? "checked" : ""}
						style={{
							margin: 0,
						}}
					>
						{inputNode}
					</Form.Item>
				) : inputType === "radio" ? (
					<Form.Item
						name={dataIndex}
						style={{
							margin: 0,
						}}
					>
						{inputNode}
					</Form.Item>
				) : (
					<Form.Item
						name={dataIndex}
						style={{
							margin: 0,
						}}
						placeholder={title}
						rules={[
							{
								required: true,
								message: `Please Input ${title}!`,
							},
						]}
					>
						{inputNode}
					</Form.Item>
				)
			) : (
				children
			)}
		</td>
	);
};
