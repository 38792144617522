import { ExclamationCircleFilled } from "@ant-design/icons";
import { notification } from "antd";
import { ref, update } from "firebase/database";
import React, { useState, useRef } from "react";

import { db } from "../firebase/initFirebase";
import { ViewExpandedRowRender } from "./ViewExpandedRowRender";
import { ViewingTable } from "./ViewingTable";
import ChangeLog from "./ChangeLog";
import CommentSection from "./CommentSection";
import "../styling/Table.css";
import useGetUser from "../hooks/useGetUser";
import useGetTable from "../hooks/useGetTable";
import Columns from "./Columns";
export const MainTable = ({ tableUid }) => {
	const [expandedKey, setExpandedKey] = useState(null);
	const [openComments, setOpenComments] = useState(false);
	const [openChangeLog, setOpenChangeLog] = useState(false);
	const [api, contextHolder] = notification.useNotification();
	const searchInput = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	const [activeUser] = useGetUser();
	const [dataSource] = useGetTable(0, tableUid);
	console.log(dataSource);
	const [filter, setFilter] = useState({
		uncontrived: false,
		obviousStart: false,
		greatRock: false,
		flatLanding: false,
		tall: false,
		beautifulSetting: false,
		sent: false,
		searching: false,
		searchText: "",
		sorted: false,
	});

	const openNotification = (placement, type) => {
		api.info({
			message: type === "vote" ? "Login to vote" : "Login to comment",
			className: "notification",
			placement,
			style: {
				color: "white",
			},
			icon: (
				<ExclamationCircleFilled
					style={{
						color: "#d4371c",
					}}
				/>
			),
		});
	};
	const handleVote = (currentUid, key, vote) => {
		try {
			var upvoteUserList = dataSource.data[key].upvote
				? dataSource.data[key].upvote
				: [];
			var downvoteUserList = dataSource.data[key].downvote
				? dataSource.data[key].downvote
				: [];

			if (upvoteUserList.includes(currentUid) && vote) {
				upvoteUserList.pop(currentUid);
			} else if (downvoteUserList.includes(currentUid) && !vote) {
				downvoteUserList.pop(currentUid);
			}

			//if user clicks upvote and they have not already upvoted
			else if (!upvoteUserList.includes(currentUid) && vote) {
				upvoteUserList.push(currentUid);
				if (downvoteUserList.includes(currentUid)) {
					downvoteUserList.pop(currentUid);
				}
			}

			//if user clicks downvote and they have not already downvoted
			else if (!downvoteUserList.includes(currentUid) && !vote) {
				downvoteUserList.push(currentUid);
				if (upvoteUserList.includes(currentUid)) {
					upvoteUserList.pop(currentUid);
				}
			}
			const updates = {};
			const path = "/tables/" + tableUid + "/data/" + key;
			updates[path + "/upvote/"] = upvoteUserList;
			updates[path + "/downvote/"] = downvoteUserList;
			update(ref(db), updates);
		} catch (e) {
			console.log(e);
			// saveFailed.current = true;
		}
	};

	const onExpand = (_, { key }) => {
		expandedKey === key ? setExpandedKey(null) : setExpandedKey(key);
	};

	const expandedRowRender = (record) => {
		return (
			<ViewExpandedRowRender
				record={record}
				tableName={dataSource.tableName}
				tableUid={tableUid}
			/>
		);
	};

	let locale = {
		emptyText: (
			<span>
				<p>Whoopsie</p>
			</span>
		),
	};

	return (
		<div>
			{contextHolder}

			{(() => {
				if (openComments) {
					return (
						<CommentSection
							setOpenComments={setOpenComments}
							setOpenChangeLog={setOpenChangeLog}
							user={activeUser}
							tableName={dataSource.tableName}
							tableUid={tableUid}
							openNotification={openNotification}
						/>
					);
				} else if (openChangeLog) {
					return (
						<ChangeLog
							setOpenComments={setOpenComments}
							setOpenChangeLog={setOpenChangeLog}
							tableName={dataSource.tableName}
							tableUid={tableUid}
						/>
					);
				} else {
					return (
						<ViewingTable
							tableName={dataSource.tableName}
							setOpenComments={setOpenComments}
							setOpenChangeLog={setOpenChangeLog}
							locale={locale}
							expandedKey={expandedKey}
							dataSource={dataSource.data}
							expandedRowRender={expandedRowRender}
							onExpand={onExpand}
							user={activeUser}
							handleVote={handleVote}
							openNotification={openNotification}
							defaultColumns={Columns(
								filter,
								setFilter,
								dataSource,
								openNotification,
								handleVote,
								activeUser,
								searchInput,
								searchText,
								setSearchText,
								searchedColumn,
								setSearchedColumn
							)}
						/>
					);
				}
			})()}
		</div>
	);
};
